<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12 md3 my-auto>
        <h1>Notificaciones</h1>
      </v-flex>

      <v-flex xs12 md5 class="text-center">
        <h3>Canales</h3>
        <v-chip-group @change="filter" center-active
          v-model="selected_channels" multiple >
          <v-chip v-for="item in myChannels" 
            outlined :key="item.channelName" active-class="primary--text">{{ item.description? item.description : item.channelName }}</v-chip>
        </v-chip-group>
      </v-flex>

      <v-flex xs6 md2 class="text-center">
        <h3>Sólo mías</h3>
        <v-flex switch-center>
          <v-switch v-model="me" @change="changeOwn"></v-switch>
        </v-flex>
      </v-flex>

      <v-flex xs6 md2 class="text-center">
        <h3>Modo tabla</h3>
        <v-flex switch-center>
          <v-switch v-model="tableMode" @change="changeMode"></v-switch>
        </v-flex>
      </v-flex>
    </v-layout>

    <v-progress-linear v-show="loading" :indeterminate="loading" color="primary" class="mt-1">
    </v-progress-linear>
    <v-layout row wrap v-show="tableMode">
      <v-simple-table fixed-header style="width:100%" class="dataTable">
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                Título
              </th>
              <th>
                Descripción
              </th>
              <th>
                Fecha
              </th>
            </tr>
          </thead>
          <tbody class="dataTable">
            <tr v-for="item in notifications" :key="item._id">
              <td style="min-width:200px">
                {{
                  item.alert
                    ? item.alert.title
                    : item.data && item.data.title
                    ? item.data.title
                    : ""
                }}
              </td>
              <td>
                <pre>{{
                  item.alert ? item.alert.body : item.data && item.data.body ? item.data.body : ""
                }}
                </pre>
                <!--Aditional data-->        
                <div class="more-info" v-if="item.data && item.data.properties">
                  <ul>
                    <li v-for="d in item.data.properties" :key="d.value">
                      {{
                        Array.isArray(d) ? d[1] + ": " + item.data[d[0]] : d + ": " + item.data[d]
                      }}
                    </li>
                  </ul>
                </div>        
              </td>
              <td>{{ new Date(item.instant).toLocaleString() }}</td>
              <td>
                <v-btn v-if="item.data && item.data.url" icon :href="item.data.url" target="_blank">
                  <v-icon>link</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-layout>
    <v-layout row wrap v-show="!tableMode">
      <v-row>
        <Notification
          v-for="item in notifications"
          :key="item._id"
          :notification="item"
        ></Notification>
      </v-row>
    </v-layout>

    <v-card v-intersect="infiniteScrolling"></v-card>

  </v-container>
</template>

<script>
import Vue from "vue";
import Notification from "../components/Notification.vue";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

export default {
  name: "Notifications",
  components: { Notification },
  data: () => ({
    notifications: [],
    myChannels: null,
    selected_channels: [],
    me: false,
    tableMode: true,
    page: 0
  }),
  mounted() {
    var mode = Vue.$cookies.get("mode");

    if (mode != null) {
      this.tableMode = mode === "true";
    }
  },
  created() {
    
    this.$store.dispatch("fetchChannels").then(() => {
      this.$store.dispatch("fetchUserChannels").then(res => {
        
        this.myChannels = [];        

        // obtención de los canales seleccionados por el usuario
        for (let i = 0; i < this.channels.length; i++) {
          for (let j = 0; j < res.length; j++) {
            if (res[j].toLowerCase() == this.channels[i].channelName.toLowerCase()) {
              this.myChannels.push(this.channels[i]);
            }
          }
        }
      
      });

    });

    this.$messaging.getToken({ vapidKey: "BPovmIh1otNekHN7B2aKaePCQSTdpffdy--CVeNdNz2S392GVuUed12mA1s-YIocLjcFT14YCFh4KyJvuxrWXuo"})
      .then(response => {
        //this.$store.dispatch("registerDevice", response);
        this.fcm_token = response;
      });

  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    user() {
      return this.$store.getters.getUser;
    },
    channels() {
      return this.$store.getters.getChannels;
    },
  },
  methods: {
    changeOwn(value = null) {

      this.notifications = [];
      this.page = 1;

      if (!value)
        value = this.me;
      
      this.refreshData();
    },
    changeMode(value) {
      Vue.$cookies.set("mode", value);
      //Vue.$cookies.set("mode", value, "7d");
    },
    filter() {
      this.notifications = [];
      this.page = 1;
      this.refreshData();
    },
    refreshData(){

      let filter = {own:this.me, page: this.page}

      if (this.selected_channels.length != 0)
      {
        let channel_filter = "";
        this.selected_channels.forEach(e => {
          channel_filter += this.myChannels[e].channelName.toLowerCase() + ",";
        });
        filter.channels = channel_filter;
      }

      this.$store.dispatch("fetchNotifications", filter).then(res => { this.notifications = this.notifications.concat(res)});
    },
    infiniteScrolling() {                    
        this.page++;
        this.refreshData();  
    },
    getChannelDescription(name){

      let ch = this.allChannels.find(c=>c.channelName == name); 

      if (ch.description != "")
        return ch.description;
      else return ch.channelName;
 
    },
  },
};
</script>

<style scoped>
.switch-center {
  display: flex;
  justify-content: center;
  margin-top: -20px;
}

.dataTable tr td
{
  font-size:12px !important;
}

pre {margin-top: 10px;}

.more-info
{
  background-color: #eee;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 5px;
}
</style>
