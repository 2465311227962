<template>
  <v-col cols="12">
    <v-card elevation="3" class="mx-2">
      <v-card-title v-if="notification.alert" class="text-h6">
        {{ notification.alert.title }}
      </v-card-title>

      <v-card-title v-if="notification.data && notification.data.title" class="text-h6">
        {{ notification.data.title }}
      </v-card-title>

      <v-card-subtitle v-if="notification.alert" class="ma-1">
        <pre>{{ notification.alert.body }}</pre>
      </v-card-subtitle>

      <v-card-subtitle v-if="notification.data && notification.data.body" class="ma-1">
        <pre>{{ notification.data.body }}</pre>
      </v-card-subtitle>

      <v-layout v-if="notification.data && notification.data.properties">
        <v-container>
          <v-row class="ml-5 mr-5">
            <v-col cols="12" md="3" sm="6" v-for="item in notification.data.properties" :key="item.value">
              <v-text-field
                style="margin-bottom:-10px;"
                v-if="Array.isArray(item)"
                :label="item[1]"
                :value="notification.data[item[0]]"
                outlined
                readonly
              ></v-text-field>
              <v-text-field
                style="margin-bottom:-10px;"
                v-else
                :label="item"
                :value="notification.data[item]"
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-layout>
      <v-card-actions>
        <v-btn v-if="notification.channel" color="primary" text>
          {{ getChannelName(notification.channel) }}
        </v-btn>
        <v-btn v-if="notification.data && notification.data.url"
          color="primary"
          text
          :href="notification.data.url"
          target="_blank">
          <v-icon>link</v-icon>
          Enlace
        </v-btn>
        <div class="nDate">{{ new Date(notification.instant).toLocaleString() }}</div>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "Notification",
  data: () => ({}),
  props: { notification: Object },
  computed: {
    channels() {
      return this.$store.getters.getChannels;
    },
  },
  methods: {

    getChannelName: function(name) {

      if (!name)
        return "qqq";
      let _c = this.channels.filter(c => c.channelName.toLowerCase() == name.toLowerCase());
      if (_c && _c.length > 0){      
        if (_c[0].description && _c[0].description != "")
          return _c[0].description;
        else return _c[0].channelName; 
      }
      
    }

  },
};
</script>



<style scoped>
.nDate {
  position: absolute;
  right: 20px;
  font-size: 14px;
  color: #888;
  bottom: 16px;
}
</style>
